<template>
  <div class="basic-data-list">
    <el-card class="table-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="top"
        :label-width="120"
        :button="button"
        class="basic-data-add-form"
        @submit="handleSubmit"
        @reset="handleReset"
      />
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @delItem="delItem"
        @pageChange="pageChange"
      >
        <template #enableStatus="{ row }">
          <el-switch
            :value="row.enableStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            @click.native="handleChangeEnableStatus(row)"
          />
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
import MTable from '@/libs_sz/components/MTable/MTable'
import { getEditBaseFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import { mapState } from 'vuex'
export default {
  name: 'InterfaceLogList',
  components: {
    MTable
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      button: {
        ok: this.$t('lang.gles.common.addNew'),
        cancel: this.$t('lang.gles.common.reset')
      },
      pageData: {
        currentPage: 1,
        pageSize: 20
      },
      tableExtendConfig: {
        sortNum: true,
        operateWidth: '60px',
        operate: [
          {
            event: 'delItem',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('interfaces', ['interfaceTypeList']),
    baseFormData() {
      return getEditBaseFormData(this, this.formModel)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.initSelectList()
    this.doSearch()
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      if (!this.interfaceTypeList?.length) {
        this.$store.dispatch('interfaces/getInterfaceTypeList')
      }
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getInterfaceConfigList') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.interfaces(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        const list = recordList.map((item) => {
          return {
            ...item,
            interfaceTypeName:
              this.interfaceTypeList?.find(
                (t) => t.value === item.interfaceType
              )?.label ?? ''
          }
        })
        this.tableData = list || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    /**
     * 页码改变
     * @param {*} pageData
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.doSearch()
    },
    /**
     * 停用
     */
    async handleChangeEnableStatus(row) {
      const { code } = await this.$httpService(
        this.$apiStore.interfaces('changeInterfaceStatus'),
        {
          id: row.id,
          status: row.enableStatus ? 0 : 1
        }
      )
      if (code) return
      row.enableStatus = row.enableStatus ? 0 : 1
    },
    /**
     * 删除接口
     */
    delItem({ row }) {
      this.$httpService(this.$apiStore.interfaces('deleteInterfaceConfig'), {
        ids: [row.id]
      }).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
        if (this.tableData?.length < 2) {
          this.pageData.currentPage = 1
        }
        this.doSearch()
      })
    },
    /**
     * 表单项改变事件
     */
    onFormChange(val, formItem) {
      if (typeof val === 'string') {
        this.$set(this.formModel, formItem.name, this._.trim(val))
      } else {
        this.$set(this.formModel, formItem.name, val)
      }
    },
    /**
     * 新增
     */
    handleSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        const params = { ...model, enableStatus: 0 }
        const { code } = await this.$httpService(
          this.$apiStore.interfaces('addInterfaceConfig'),
          params
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
        this.doSearch()
        this.handleReset()
      })
    },
    /**
     * 重置
     */
    handleReset() {
      this.$refs.myForm.clearValidate()
    }
  }
}
</script>
