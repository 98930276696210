<template>
  <div class="container-type">
    <component
      :is="currentCom"
      :mode="mode"
      :row-detail.sync="row"
      @updateCom="updateCom"
      @update:row-detail="rowDetail = $event"
    />
  </div>
</template>
<script>
import InterfaceConfigList from './components/list.vue'
export default {
  components: {
    InterfaceConfigList
  },
  data() {
    return {
      currentCom: 'InterfaceConfigList',
      mode: '',
      row: {}
    }
  },
  created() {
    this.$EventBus.$on('page:updateCom', this.updateCom)
  },
  methods: {
    updateCom({ currentCom, mode, row = {}}) {
      console.log('sdsd')
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      console.log(this.currentCom, this.mode, this.row)
    }
  }
}
</script>
