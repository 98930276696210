import mapVerify from '@/utils/formValidate'

// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 接口类型
    {
      prop: 'interfaceTypeName',
      label: `${that.$t('lang.gles.interface.interfaceType')}`,
      isShow: true
    },
    // 回调地址
    {
      prop: 'callbackAddress',
      label: that.$t('lang.gles.interface.callbackAddress'),
      isShow: true
    },
    // 客户端代码
    {
      prop: 'clientCode',
      label: `${that.$t('lang.gles.interface.clientCode')}`,
      isShow: true
    },
    // 客户端代码
    {
      prop: 'enableStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      slotName: 'enableStatus'
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 接口类型
    {
      name: 'interfaceType',
      value: row.interfaceType || '',
      span: 6,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.interfaceType'),
      rules: mapVerify(['required']),
      data: that.interfaceTypeList,
      autoFocus: false
    },
    // 回调地址
    {
      name: 'callbackAddress',
      value: row.callbackAddress || '',
      span: 6,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.callbackAddress'),
      rules: mapVerify(['required']),
      autoFocus: false
    },
    // 客户端代码
    {
      name: 'clientCode',
      value: row.clientCode || '',
      span: 6,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.interface.clientCode'),
      rules: mapVerify(['required', 'inputCode']),
      autoFocus: false
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}

